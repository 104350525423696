<!--
 * @Author: 智客云网络科技
 * @Date: 2022-03-30 18:29:12
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-07-21 21:07:01
 * @Description: 组织选择器
 * @FilePath: \src\components\organizationSelect\index.vue
-->

<template>
  <div :style="{ display: 'inline-block' }">
    <el-select v-model="organization_id" size="small" :disabled="select_lock">
      <el-option v-if="allSelect" key="all" label="所有" value="all" />
      <el-option
        v-for="item in organization_list"
        :key="item.org_id"
        :label="item.org_name"
        :value="item.org_id"
      />
    </el-select>
  </div>
</template>

<script>
import { getCurrentInstance, ref, onMounted, watch, nextTick } from "vue";
import { getOrganizationListApi } from "@/api/module/Organization"; //接口

export default {
  name: "organizationSelect",

  props: {
    value: {
      type: String,
      default: "",
    }, //组织ID
    all: {
      type: Boolean,
      default: false,
    },
  },

  meits: ["update:modelValue"],

  setup(props, context) {
    const internalInstance = getCurrentInstance();
    const utils = internalInstance.appContext.config.globalProperties.$utils;

    /* ============================== 定义data ============================== */
    const allSelect = ref(false); //是否启用所有筛选
    const select_lock = ref(false); //锁定组织选择
    const organization_id = ref(""); //组织ID
    const organization_list = ref([]); //组织列表

    /* ============================== 处理watch ============================== */

    //监听行数变化
    watch(
      () => organization_id.value,
      (newVal) => {
        context.emit("update:modelValue", newVal);
      }
    );

    /* ============================== 定义函数 ============================== */

    //加载组织列表数据
    const loadOrgList = () => {
      getOrganizationListApi()
        .then((res) => {
          organization_list.value = res.data; //重写数据
          if (res.data.length === 1) {
            nextTick(() => {
              organization_id.value = res.data[0]["org_id"];
            });
            select_lock.value = true;
          } else if (allSelect) {
            organization_id.value = "all";
          } else {
            nextTick(() => {
              organization_id.value = res.data[0]["org_id"];
            });
          }
        })
        .catch((err) => {
          utils.fail(err.err_msg);
        });
    };

    onMounted(() => {
      allSelect.value = props.all;
      loadOrgList(); //加载组织列表
    });

    /* ============================== 返回数据 ============================== */
    return {
      select_lock,
      organization_id,
      organization_list,
      loadOrgList,
      allSelect,
    };
  },
};
</script>
