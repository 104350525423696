/*
 * @Author: 智客云网络科技
 * @Date: 2022-02-27 16:42:03
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-27 16:51:45
 * @Description: 系统状态管理模块
 * @FilePath: \src\store\WebModule.js
 */

const module = {
  namespaced: true, // 命名空间
  // 公共全局状态
  state: {
    jump_state: false, //状态
    method: "", //跳转方法 Inner内链 Outer外链
    target_url: "", //目标地址
  },
  // 集中式修改状态的方法
  mutations: {
    //设置跳转目标
    setJumpTarget: (state, payload1, payload2) => {
      state.jump_state = true;
      state.method = payload1;
      state.target_url = payload2;
    },

    //清除跳转目标
    removeJumpTarget: (state) => {
      state.jump_state = false;
      state.method = "";
      state.target_url = "";
    },
  },
  // 异步请求的方法
  actions: {},
};
// 导出去
export default module;
