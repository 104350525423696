/*
 * @Author: 智客云网络科技
 * @Date: 2022-02-27 10:22:34
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-04-02 23:06:39
 * @Description: 请输入文件描述信息
 * @FilePath: \src\main.js
 */
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import utils from "@/utils";
import ElementPlus from "element-plus";
import locale from "element-plus/lib/locale/lang/zh-cn";
import * as ElIconModules from "@element-plus/icons-vue";

import eIconPicker, { iconList } from "e-icon-picker";
import "e-icon-picker/lib/symbol.js"; //基本彩色图标库
import "e-icon-picker/lib/index.css"; //基本样式，包含基本图标
import "font-awesome/css/font-awesome.min.css"; //font-awesome图标库
import "e-icon-picker/lib/ele/icon.css"; //element-plus图标库
import el from "e-icon-picker/lib/ele/element-plus.data"; //element-plus的图标
import fontAwesome470 from "e-icon-picker/lib/fontawesome/font-awesome.v4.7.0.data"; //fontAwesome470的图标

// import fontAwesome5 from "e-icon-picker/lib/fontawesome/fontawesome-5.15.4.data"; //fontAwesome5.x.x的图标
// import fontAwesome5shims from "e-icon-picker/lib/fontawesome/v4-shims-5.15.4.data"; //fontAwesome5.x.x shims的图标
// import fontAwesome5pro from "e-icon-picker/lib/fontawesome/pro-5.15.4.data"; //fontAwesome5.x.x pro的图标
// import all5 from "e-icon-picker/lib/fontawesome/all-5.15.4.data"; //fontAwesome5.x.x all的图标
// import all6 from "e-icon-picker/lib/fontawesome/all-6.0.0.data"; //fontAwesome6.x.x all的图标
// import brands6 from "e-icon-picker/lib/fontawesome/brands-6.0.0.data"; //fontAwesome6.x.x brands的图标
// import fontawesome6 from "e-icon-picker/lib/fontawesome/fontawesome-6.0.0.data"; //fontAwesome6.x.x fontawesome的图标
// import fontAwesome6shims from "e-icon-picker/lib/fontawesome/v4-shims-6.0.0.data"; //fontAwesome6.x.x shims的图标

import "element-plus/dist/index.css";
import "./globa.css";

//自定义组件
import tableSearch from "@/components/tableSearch"; //表格搜索组件-主体
import tableSearchItem from "@/components/tableSearchItem"; //表格搜索组件-搜索项
// import tablePagination from "@/components/tablePagination"; //表格分页
import organizationSelect from "@/components/organizationSelect"; //表格分页
import kcTips from "@/components/kcTips"; //表格分页

const app = createApp(App);

// 统一注册Icon图标
for (const iconName in ElIconModules) {
  if (Reflect.has(ElIconModules, iconName)) {
    const item = ElIconModules[iconName];
    app.component(iconName, item);
  }
}

//注册自定义组件
app.component("table-search", tableSearch);
app.component("table-search-item", tableSearchItem);
// app.component("table-pagination", tablePagination);
app.component("org-select", organizationSelect);
app.component("kc-tips", kcTips);

app.use(eIconPicker, {
  FontAwesome: true,
  ElementUI: true,
  eIcon: true, //自带的图标，来自阿里妈妈
  eIconSymbol: true, //是否开启彩色图标
  addIconList: [],
  removeIconList: [],
  zIndex: 3600, //选择器弹层的最低层,全局配置
});

// let fa470 = analyzingIconForIconfont(fontAwesome470); //解析class
iconList.addIcon(el); //element-plus的图标
iconList.addIcon(fontAwesome470); //fontAwesome470的图标
console.log(fontAwesome470);

app.use(ElementPlus, { locale });
app.use(utils);
app.use(store);
app.use(router);
app.mount("#app");
