/*
 * @Author: 智客云网络科技
 * @Date: 2022-03-01 14:03:38
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-05-03 08:51:22
 * @Description: 菜单-导航-路由 联动模块
 * @FilePath: \src\router\MenuLinkage.js
 */

import { toRaw } from "vue";
import { myStore } from "@/store";

const store = myStore();
const state = store.state;

/**
 * 路由变动时调用 激活导航栏
 * @param {string} id 菜单ID
 * 例: ManageMenu Home 等
 */
function activeNav(route) {
  //   console.log("activeNav", route);
  var menu = getMenuById(state.MenuModule.menuConfig, route.meta.menu_id); //通关ID获取菜单
  if (menu !== null) {
    //如果是当前正在显示的tab 则直接返回
    if (menu.id == state.MenuModule.showMenu.id) {
      return false;
    }

    //如果导航不存在则创建导航
    if (getOpenTabById(menu.id) === null) {
      store.commit("addCashViews", menu.route); //页面名称写入缓存
      store.commit("MenuModule/addOpenTableList", menu); //添加Tab
    }

    //切换当前显示的菜单(导航)
    setTabBar(route);
  }
}

/**
 * 根据ID获取已打开的Tab
 * @param {string} id
 * @returns
 */
function getOpenTabById(id) {
  let openTabList = state.MenuModule.openTableList;
  for (var i = 0; i < openTabList.length; i++) {
    if (openTabList[i].id === id) {
      return openTabList[i];
    }
  }
  return null;
}

/**
 * 获取指定index的菜单
 * @param {object} menuList 菜单组
 * @param {string} id 菜单ID
 * @returns
 */
function getMenuById(menuList, id) {
  if (typeof menuList !== "object") {
    // console.log(menuList);
    return null;
  }

  for (var i = 0; i < menuList.length; i++) {
    var menu = menuList[i];
    if (menu.id + "" === id + "") {
      return menu;
    }
    //处理多级菜单
    if (menu.childList) {
      var menu2 = getMenuById(menu.childList, id);
      if (menu2 !== null) {
        return menu2;
      }
    }
  }
  return null;
}

/**
 * 设置当前显示的Tab
 * @param {object} to
 * @returns
 */
function setTabBar(to) {
  if (to.name === undefined) {
    return false;
  }

  let tabs = toRaw(store.state.MenuModule.openTableList);
  let tab_index = arrayGetIndex(tabs, "route", to.name);
  if (tab_index === undefined) {
    return false;
  }

  let tab = tabs[tab_index];
  if (tab === undefined) {
    return false;
  }

  store.commit("MenuModule/setShowMenu", tab);
}

/**
 * 根据指定数据从数组里获取index
 * @param {*} arr 数组
 * @param {*} prop 数据键名
 * @param {*} value 标定数据
 * @returns
 */
function arrayGetIndex(arr, prop, value) {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i][prop] == value) {
      return i;
    }
  }
  return null;
}

export default activeNav;
