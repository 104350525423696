/*
 * @Author: 智客云网络科技
 * @Date: 2022-02-06 20:06:58
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-08 00:16:41
 * @Description: 路由状态管理模块
 * @FilePath: \src\store\RouterModule.js
 */

const module = {
  namespaced: true, // 命名空间
  // 公共全局状态
  state: {
    originalRoutes: [], //原始路由表
    routes: [], //路由表
    routerStatus: false, //路由加载状态
  },
  // 集中式修改状态的方法
  mutations: {
    //设置原始路由表
    setOriginalRoutes: (state, payload) => {
      state.originalRoutes = payload;
    },

    //设置路由表
    setRoutes: (state, payload) => {
      state.routes = payload;
    },

    //设置路由状态
    setRouterStatus: (state, payload) => {
      state.routerStatus = payload;
    },
  },
  // 异步请求的方法
  actions: {},
};
// 导出去
export default module;
