/*
 * @Author: 智客云网络科技
 * @Date: 2022-02-06 18:38:21
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-04-11 11:45:43
 * @Description: 请输入文件描述信息
 * @FilePath: \src\router\routes.js
 */

const routes = [
  {
    path: "/",
    redirect: "/Home",
  },
  {
    id: "a6s28w1g74",
    title: "Main",
    name: "Main",
    path: "/",
    component: () => import("@/components/main/index.vue"),
    meta: {
      menu_id: "a6s28w1g74", //对应的菜单ID
      verify: 0, //认证标识
    },
    children: [],
  },
  {
    id: "login",
    title: "登录页",
    name: "Login",
    path: "/Login",
    component: () => import("@/views/Login.vue"),
    meta: {
      menu_id: "", //对应的菜单ID
      verify: 0, //认证标识
    },
  },
  {
    id: "500",
    title: "500",
    name: "500",
    path: "/500",
    component: () => import("@/views/500.vue"),
    meta: {
      menu_id: "", //对应的菜单ID
      verify: 0, //认证标识
    },
  },
  {
    id: "403",
    title: "403",
    name: "403",
    path: "/403",
    component: () => import("@/views/403.vue"),
    meta: {
      menu_id: "", //对应的菜单ID
      verify: 0, //认证标识
    },
  },
];

export default routes;
