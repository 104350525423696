<!--
 * @Author: 智客云网络科技
 * @Date: 2022-02-27 10:22:34
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-27 11:00:50
 * @Description: 请输入文件描述信息
 * @FilePath: \src\App.vue
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>
