/*
 * @Author: 智客云网络科技
 * @Date: 2021-04-13 20:38:32 +0800
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-03-27 12:10:19
 * @Description: 更改store中状态的唯一方法
 * @FilePath: \src\store\mutations.js
 */

const mutations = {
  //设置主题
  setThemeV: (state, payload) => {
    state.themeV = payload;
  },

  //设置主题切换状态
  setThemeToggling: (state, payload) => {
    state.themeToggling = payload;
  },

  //设置系统初始化状态
  setSystemInit: (state) => {
    state.systemInit = true;
  },

  //追加需要缓存的页面
  addCashViews: (state, TabId) => {
    state.cashViews.push(TabId); //向数组末尾添加对象
  },

  //移除需要缓存的页面
  removeCashViews: (state, TabId) => {
    var index = state.cashViews.indexOf(TabId);
    if (index > -1) {
      state.cashViews.splice(index, 1);
    }
  },
};

export default mutations;
