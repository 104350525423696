/*
 * @Author: 智客云网络科技
 * @Date: 2022-03-25 18:46:02
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-03-30 18:31:18
 * @Description: 请输入文件描述信息
 * @FilePath: \src\api\module\organization.js
 */
import http from "@/api/api";

// 创建组织
export const createOrganizationApi = (params) =>
  http.post("/v1/Auth/CreateOrganization", params);

// 修改组织
export const updateOrganizationApi = (params) =>
  http.put("/v1/Auth/UpdateOrganization", params);

// 删除组织
export const deleteOrganizationApi = (params) =>
  http.delete("/v1/Auth/DeleteOrganization", params);

// 获取组织信息
export const getOrganizationInfoApi = (params) =>
  http.get("/v1/Auth/GetOrganizationInfo", params);

// 获取根组织列表
export const getRootOrganizationListApi = (params) =>
  http.get("/v1/Auth/GetRootOrganizationList", params);

// 获取组织列表
export const getOrganizationListApi = (params) =>
  http.get("/v1/Auth/GetOrganizationList", params);

// 获取组织表格
export const getOrganizationTableApi = (params) =>
  http.get("/v1/Auth/GetOrganizationTable", params);
