/*
 * @Author: 智客云网络科技
 * @Date: 2022-02-06 20:06:58
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-03-01 14:16:24
 * @Description: 路由状态管理模块
 * @FilePath: \src\store\MenuModule.js
 */

const module = {
  namespaced: true, // 命名空间
  // 存放数据的地方
  state: {
    originalMenuConfig: [], //原始菜单表
    menuConfig: [], //菜单表
    menuStatus: false, //菜单初始化状态
    openTableList: [], //当前打开的菜单列表
    showMenu: {}, //当前显示的菜单
    scrollX: 0, //导航栏基准位置
    menuIsFold: false, //菜单是否折叠
    rightMenuIsFold: false, //右边是否折叠 将右边盒子折叠与菜单折叠分开，这样可以减少动画的卡顿现象
    homeOpenState: false, //首页开启状态
  },

  //派生数据
  getters: {},

  //同步改变
  mutations: {
    //设置原始菜单表
    setOriginalMenuConfig: (state, payload) => {
      state.originalMenuConfig = payload;
    },

    //设置菜单表
    setMenuConfig: (state, payload) => {
      state.menuConfig = payload;
    },

    //设置菜单状态
    setMenuStatus: (state, payload) => {
      state.menuStatus = payload;
    },

    //设置显示的菜单
    setShowMenu: (state, payload) => {
      state.showMenu = payload;
    },

    //追加已打开页面
    addOpenTableList: (state, payload) => {
      state.openTableList.push(payload);
    },

    //移除已打开页面
    removeOpenTableList: (state, _vm) => {
      let cv = state.openTableList;
      _vm.utils.arrayDelete(cv, _vm.tab);
      state.openTableList = cv;
    },

    //设置导航栏基准位置
    setScrollX: (state, payload) => {
      state.scrollX = payload;
    },

    //增加导航栏基准位置
    addscrollX: (state, payload) => {
      state.scrollX += payload;
    },

    //减少导航栏基准位置
    subscrollX: (state, payload) => {
      state.scrollX -= payload;
    },

    //设置菜单折叠状态
    setMenuFold: (state, payload) => {
      state.menuIsFold = payload;
    },

    //设置右侧菜单折叠状态
    setRightMenuFold: (state, payload) => {
      state.rightMenuIsFold = payload;
    },

    //设置首页开启状态
    openHome: (state) => {
      state.homeOpenState = true;
    },
  },
  //异步改变
  actions: {},
};
//导出
export default module;
