<!--
 * @Author: 智客云网络科技
 * @Date: 2022-03-03 13:37:27
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-03-30 14:50:39
 * @Description: 请输入文件描述信息
 * @FilePath: \src\components\tableSearchItem\index.vue
-->

<template>
  <div class="table-search-item" :style="{ width: props.width }">
    <label v-if="props.title !== '' && props.title !== undefined">
      {{ props.title }}：
    </label>
    <slot />
  </div>
</template>

<script>
export default {
  name: "TableSearchItem",
  props: {
    title: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "18em",
    },
  },
  setup(props) {
    return {
      props,
    };
  },
};
</script>

<style scoped>
.table-search-item {
  /* width: 18em; */
  height: 30px;
  line-height: 30px;
  display: inline-block;
  margin: 0.5em 0.2em;
}

.table-search-item label {
  width: 5em;
  color: rgb(53, 53, 53);
  font-weight: 600;
  display: inline-block;
  text-align: right;
}
</style>

<style>
/** 表格搜索条样式 内容组件样式 **/
.table-search-item .el-input {
  width: 13em;
  display: inline-block;
}

.from-rightselect,
.from-rightselect .el-form-item {
  width: 100%;
}

.from-rightselect .el-form-item__content {
  width: 80%;
}
</style>
