<!--
 * @Author: 智客云网络科技
 * @Date: 2022-03-31 00:23:26
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-03-31 00:42:47
 * @Description: 提示组件
 * @FilePath: \src\components\kcTips\index.vue
-->

<template>
  <div class="tips-box">
    <el-icon :size="16"><warning /></el-icon>
  </div>
</template>

<style>
.tips-box {
  /* padding: 0 0.3em; */
  line-height: 1.8em;
  display: inline-block;
  color: #0077ff;
  text-align: center;
  margin: auto;
}

.tips-box i {
  height: 1.8em;
}

.tips-box i svg {
  padding-top: 0.4em;
}
</style>
