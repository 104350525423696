/*
 * @Author: 智客云网络科技
 * @Date: 2021-04-13 20:38:25 +0800
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-01-21 23:59:09
 * @Description: 异步更改状态
 * @FilePath: \src\store\actions.js
 */

const actions = {
  //使用异步方法修改版本号 示例
  SET_VERSION: (state, version) => {
    setTimeout(() => {
      state.commit("SET_VERSION", version);
    }, 1000);
  },
};

export default actions;
