<!--
 * @Author: 智客云网络科技
 * @Date: 2022-03-03 13:12:51
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-03-30 17:21:25
 * @Description: 请输入文件描述信息
 * @FilePath: \src\components\tableSearch\index.vue
-->

<template>
  <div class="table-search">
    <div class="table-search-title">信息检索</div>
    <div class="table-search-content">
      <slot />
      <!-- table-search-body -->
      <div class="table-search-btn">
        <el-button
          type="primary"
          size="small"
          icon="finished"
          plain
          @click="onQuery()"
        >
          查询
        </el-button>
        <el-button
          type="info"
          size="small"
          icon="refresh"
          plain
          @click="onReset()"
        >
          重置
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableSearch",
  setup(props, context) {
    // 点击查询按钮回调
    function onQuery() {
      context.emit("onQuery", 888);
    }

    //点击重置按钮回调
    function onReset() {
      context.emit("onReset", 888);
    }

    return {
      onQuery,
      onReset,
    };
  },
};
</script>

<style scoped>
.table-search {
  width: 100%;
  padding: 0;
  margin: auto;
  text-align: left;
}

.table-search-title {
  font-size: 14px;
  font-weight: bold;
  line-height: 2em;
  margin-bottom: 3px;
}

.table-search-content {
  width: 100%;
  margin: 0;
  padding: 8px;
  background-color: rgb(255, 255, 255);
}

.table-search-btn {
  width: 18em;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  margin: 0.5em 0.2em;
}
</style>
