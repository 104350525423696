/*
 * @Author: 智客云网络科技
 * @Date: 2021-04-01 01:38:47 +0800
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-03-19 21:06:32
 * @Description: 公共API接口
 * @FilePath: \src\api\module\global.js
 */

// 请求方法封装
import http from "../api.js";

//获取菜单配置
export const getMenuConfigApi = () => http.get("/v1/Menu/GetMenuConfig");
